   import { AppSettings } from '../constants/constants';
import { Injectable } from '@angular/core';
import {  HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs'
import { catchError, map,share } from 'rxjs/operators';
import { HttpClient } from 'selenium-webdriver/http';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable(
   {
   providedIn: 'root'
 }
)
export class DhukanDataService {
   deviceInfo = null;
   ipAddress:any;

  constructor(private http: Http,private deviceService: DeviceDetectorService) {
   this.deviceInfo = this.deviceService.getDeviceInfo();
  // this.fnGetIpAddress();
   console.log(this.deviceInfo)
  }

  fnpostInputParams(url, params) {
    const headers = new Headers({    
      //'Content-Type': "application/JSON",
      'token': '',
      'authkey': (sessionStorage.authkey === undefined) ? 1 : JSON.parse(sessionStorage.authkey),
       // 'token': (localStorage.adtoken === undefined) ? '' : JSON.parse(localStorage.adtoken)                
    });

   
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }
  


//ProductGroup 
fnInsertProductGroup(params) {     
 
   return this.fnpostInputParams(AppSettings.insertProductGroupUrl, params);
  }
  
  fnSetProductGroup(params) {
   
   return this.fnpostInputParams(AppSettings.setProductGroupUrl, params);
  }
  
  
  getProductGroup(params) {  
   
   return this.fnpostInputParams(AppSettings.getProductGroupUrl, params);
  }
  
  
  fnDeleteProductGroup(params) {
  
  return this.fnpostInputParams(AppSettings.deleteProductGroupUrl, params);
  }
  
  fnCheckProductGroup(params) {     
  
  return this.fnpostInputParams(AppSettings.checkProductGroupUrl, params);
  }
  //Dashboard
  getDashboardSales(params) {  
   
   return this.fnpostInputParams(AppSettings.getDashboardSalesUrl, params);
  }
 //Login   
  getUserLogin(params) {  
   
   return this.fnpostInputParams(AppSettings.getUserLoginUrl, params);
  } 
  
  getUserBranch(params) {  
   
   return this.fnpostInputParams(AppSettings.getUserBranchUrl, params);
  } 
   //PushNofication
   fnSendPushNotification(title:string="",content:string="",linkid:string="",target:string="",linkid1:string="") {

    var imageUrl="https://i2.wp.com/savethehighstreet.org/wp/wp-content/uploads/2017/03/general-enquiry-icon.png?ssl=1"
    imageUrl="";
    var gameUrl="";

    let url = 'https://fcm.googleapis.com/fcm/send';
    let body = 
     {
      "data":{
        "title":title,
        "content":content,
        "imageUrl":imageUrl,
        "gameUrl":gameUrl,
        "linkid":linkid,
        "linkid1":linkid1
      },
      "to":"/topics/"+target
     };
  
    let headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'key=AAAACwdsT7c:APA91bFVVE6fcIVaVmNC5wNQ9hFJHi4vnsmvbMC8hfstoUtwrK2RvyQhTPHnShM-xwQZ1vKU_Py8x1K8zMqA4SeJC78tid9AGavTBTNSTo9S-j5lqBkRicJO1IgeVnDAT4WauYHUVcAn'
    });
    let options = { headers: headers };
  
    this.http.post(url, body,options).subscribe(data => {
     // console.log(data);
    });
  


   

   }
  


   fnSendNotification(data,deviceColl) {

      
      var imageUrl=""      
      
      if(data.notificationimgpath!=""){
         imageUrl=data.notificationimgpath;       
      }

      if(data.customimgpath!=""){
         imageUrl=data.customimgpath;       
      }

      if(data.customerid>0){
         var target=[]; 
         
         
        
         for(let abo of deviceColl){
            target.push(abo.deviceid)
         }

        

         if(target.length>0){

            let url = 'https://fcm.googleapis.com/fcm/send';
            let body = 
             {
                "notification":{
                    "title":data.title,
                    "body":data.notificationmsg,
                    "image":imageUrl,              
                  },
                  "data": {
                     "image": imageUrl,         
                     // "click_action": "FLUTTER_NOTIFICATION_CLICK",        
                     // "linktype": data.linktype,               
                     // "linktargetid":data.linktargetid,               
                  },
                  "registration_ids":target
             };
          
            
            let headers = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'key='+data.notifiapi
            });
            let options = { headers: headers };    
            return this.http.post(url, body,options);
         }

      }
      else{
         let url = 'https://fcm.googleapis.com/fcm/send';
         let body = 
         {
            "notification":{
               "title":data.title,
               "body":data.notificationmsg,
               "image":imageUrl,              
               },
               "data": {
                  "image": imageUrl,       
                  // "click_action": "FLUTTER_NOTIFICATION_CLICK",        
                  // "linktype": data.linktype,               
                  // "linktargetid":data.linktargetid,   
               },
               "to":data.target
         };
      
         let headers = new Headers({
         'Content-Type': 'application/json',
         'Authorization': 'key='+data.notifiapi
         });
         let options = { headers: headers };    
         return this.http.post(url, body,options);
      }
    

   }



   
   fnSendNotificationCart(data) {

      
      var imageUrl=""      
      
     
      let url = 'https://fcm.googleapis.com/fcm/send';
      let body = 
      {
         "notification":{
            "title":data.title,
            "body":data.notificationmsg,
            "image":imageUrl,              
            },
            "data": {
               "image": imageUrl,         
            },
            "to":data.target
      };
   
      let headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'key='+data.notifiapi
      });
      let options = { headers: headers };    
      return this.http.post(url, body, options);
      

   }

   
  private fnExeception(err:HttpErrorResponse){
    if(err.error instanceof ErrorEvent){
        console.error('Client Side Error',err.error.message);
    }else{
         console.error('Server Side Error',err);
    }
    return throwError("This is problem with dept service--"+err.error.ExceptionMessage);
}


// "data":{
//    "title":data.title,
//    "content":data.notificationmsg,
//    "imageUrl":imageUrl,
//    "gameUrl":gameUrl,
//    "linkid":0,
//    "linkid1":0
//  },

}


