import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { LoginComponent } from './components/login/login.component';

import { CompanyComponent } from './components/company/company.component';


const routes: Routes = [
  {path:'', component:DashboardComponent},
  {path:'Dashboard', component:DashboardComponent},
 
  {path:'Login', component:LoginComponent},
 
  {path:'Company', component:CompanyComponent},
 
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],  
  //imports: [RouterModule.forRoot(routes, { useHash: false })],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  //providers:[Location,{provide:LocationStrategy,useClass:PathLocationStrategy}]
})

export class AppRoutingModule { }
