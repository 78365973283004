import { Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';


@Component({
  selector: 'app-salesfilter',
  templateUrl: './salesfilter.component.html',
  styleUrls: ['./salesfilter.component.css']
})
export class SalesfilterComponent implements OnInit {

  @Input() daterange = '';
  @Input() fromdate = new Date();
  @Input() todate = new Date();
  @Input() userbranchColl: any = [];

   filtertab = 'DATERANGE';

  @Output() emitData = new EventEmitter();


  dateCustomClasses: DatepickerDateCustomClasses[];

  constructor(public appService: DhukanDataService, public loginService: LoginDataService, public route: ActivatedRoute,
    public router: Router, public toastr: ToastrService, public activeModal: NgbActiveModal,) {

    this.dateCustomClasses = [
      { date: new Date(), classes: ['bg-warning'] },
    ];
  }

  ngOnInit() {

  }

  fnDateFilter(type) {
  
    this.daterange = type;
    //this.emitData.emit(type);
    //this.activeModal.close(true);
  }

  fnApplyFilter() {

    var data = {
      daterange: this.daterange,
      fromdate: this.fromdate,
      todate:this.todate,
      userbranchColl: this.userbranchColl
    }

    this.emitData.emit(data);
    this.activeModal.close(true);
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }



}
