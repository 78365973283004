import { Component, OnInit } from '@angular/core';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  isdepartment:boolean=false;
  iscategory:boolean=false;
  issubcategory:boolean=false;
  isbrand:boolean=false;
  isproduct:boolean=false;
  isuom:boolean=false;
  isstatus:boolean=false;
  istaxgroup:boolean=false;
  isfoodsymbol:boolean=false;
  iscertificate:boolean=false;
  isspecificationtype:boolean=false;
  iscontentmanagement:boolean=false;
  isproductsection:boolean=false;
  isproductgroup:boolean=false;
  isproductgrouping:boolean=false;
  iscoupon:boolean=false;  
  ispaymentmode:boolean=false;
  iswarehouse:boolean=false;
  iscity:boolean=false;
  isarea:boolean=false;
  isvendor:boolean=false;
  isbranch:boolean=false;
  isuser:boolean=false;
  isuserrole:boolean=false;
  isdeliverytype:boolean=false;
  isdeliveryslot:boolean=false;
  isdeliveryboy:boolean=false;
  isdeliverycharges:boolean=false;
  isbanner:boolean=false;
  ispage:boolean=false;
  isorder:boolean=false;
  isshipment:boolean=false;
  iscustomer:boolean=false;
  isloyalty:boolean=false;
  iswallet:boolean=false;
  isoffer:boolean=false;
  issettings:boolean=false;
  ispricechange:boolean=false;
  issettlement:boolean=false;
  isnotification:boolean=false;
  isrefund:boolean=false;
  iscart:boolean=false;

  isrptbillsummary:boolean=false;
  isrptactivitylog:boolean=false;
  isrptsalessummary:boolean=false;
  isrptproductbycustomer: boolean = false;
  isrptsalesbycustomer: boolean = false;
  isrptsalesbyproduct:boolean=false;
  isrptsalesbybrand:boolean=false;
  isrptcancellproduct:boolean=false;

  iscatalogmenu:boolean=false;
  isordermenu:boolean=false; 



  isaccountmenu:boolean=false;
  isproductmenu:boolean=false;
  isdeliverymenu:boolean=false;
  isadminmenu:boolean=false;
  iscmsmenu:boolean=false;
  iscrmmenu:boolean=false;
  isoffermenu:boolean=false;
  isnotificationmenu:boolean=false;
  isbannermenu:boolean=false;
  isreportmenu:boolean=false; 

  

  constructor(public loginService: LoginDataService,public router: Router) {  
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    
    this.loginService.serviceloginChange.subscribe((value) => {
      this.userView = value                 
      if(this.userView.userBO){                
        this.userBO = this.userView.userBO      
       
      }
    
    }); 
  }

  ngOnInit() {
    this.loginService.fnGetUserRoleList();

    

  }



  fnLogout(){
    sessionStorage.removeItem("sbtoken");
    this.router.navigate(['/Login']);
  }


  fnReportMenuClick(type){
    try{      
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'type': type,
        }
        }

      this.router.navigate(['/Report'], navigationExtras);
    }catch(ex){
    
    }
  }
  

}
