import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { LoginDataService } from './services/dhukan/login-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewChecked  {
  
 
  title = 'Smart Boss';

  islogin:boolean=false;

  userBO:any={};
  userView: any = {};
  
  constructor(private loginService: LoginDataService, private router: Router, private bnIdle: BnNgIdleService) {
    
    this.bnIdle.startWatching(300).subscribe((res) => {
      if (res) {
        sessionStorage.removeItem("sbtoken");
        
        this.loginService.fnCheckLogin();
      }
    })

    this.fnServiceChanges();

    this.loginService.fnCheckLogin();
  }

  fnServiceChanges(){    
    
    this.loginService.serviceloginChange.subscribe((value) => {
        this.userView = value                 
        if(this.userView.userBO){                
          this.userBO= this.userView.userBO                
        }      
        
    }); 
  }


  ngOnInit(){
   
    if(sessionStorage.sbtoken === undefined){
        this.router.navigate(['/Login']);
    }
     else{
        this.loginService.fnGetUserRoleList();
    }
    
  }

  ngAfterViewChecked(): void {

    setTimeout(() =>  this.islogin=this.loginService.islogin );
     
  }

}
