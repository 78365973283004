import { BrowserModule } from '@angular/platform-browser';
import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import{ ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

  import { AppSettings } from './services/constants/constants';

//Additional 
//Model
import { NgbModule,NgbDate } from '@ng-bootstrap/ng-bootstrap';
//progress bar
import{ NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
//Toast Service
import { ToastrModule } from 'ngx-toastr';
//Select drop down
import { NgSelectModule } from '@ng-select/ng-select';
//Map
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'; 
//EDITOR
import { CKEditorModule } from 'ngx-ckeditor';
//Table
import { DataTablesModule } from 'angular-datatables';
//Date Pikker
import { BsDatepickerModule,TimepickerModule   } from 'ngx-bootstrap';
//Image Compress
import {NgxImageCompressService} from 'ngx-image-compress';
import { Ng2ImgMaxModule } from 'ng2-img-max';
//Decimal
import { NgxCurrencyModule } from "ngx-currency";
//Barcode
import { NgxBarcodeModule } from 'ngx-barcode';
//Sidebar
import { SidebarModule } from 'ng-sidebar';
//Device Info
import { DeviceDetectorModule } from 'ngx-device-detector';

//Firebase
// import {AngularFireModule} from 'angularfire2'
// import {AngularFireDatabaseModule} from 'angularfire2/database'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

//Chart
import { NgxChartsModule } from '@swimlane/ngx-charts';

//Drag
import { DragDropModule } from '@angular/cdk/drag-drop';

//Ag Grid
import { AgGridModule } from 'ag-grid-angular';

import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';

import { BnNgIdleService } from 'bn-ng-idle';



//Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';

// service
import { DhukanDataService } from './services/dhukan/dhukan-data.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';


import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { LoginComponent } from './components/login/login.component';
import { DateFormatPipe } from './date-format.pipe';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { DisablecontrolDirective } from './disablecontrol.directive';
import { HttpcancelService } from './services/dhukan/httpcancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProcessComponent } from './components/process/process.component';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore/angular-fire-firestore';
import { ImageformatterComponent } from './components/grid/imageformatter/imageformatter.component';
import { ButtonformatterComponent } from './components/grid/buttonformatter/buttonformatter.component';
import { ActionformatterComponent } from './components/grid/actionformatter/actionformatter.component';
import { CompanyComponent } from './components/company/company.component';
import { SalesfilterComponent } from './components/salesfilter/salesfilter.component';
import { LoginDataService } from './services/dhukan/login-data.service';
import { TextSearchPipePipe } from './text-search-pipe.pipe';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};




@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavigationComponent,
    HeaderComponent,
    ConfirmationDialogComponent,
    TwoDigitDecimaNumberDirectiveDirective, 
    LoginComponent,    
    DateFormatPipe,    
    DatetimeFormatPipe,    
    LoadingComponent,    
    DisablecontrolDirective,    
    ProcessComponent, 
    ImageformatterComponent,
    ButtonformatterComponent,
    ActionformatterComponent,
    CompanyComponent,
    SalesfilterComponent,
    TextSearchPipePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,  
    ReactiveFormsModule,
    NgbModule,    
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    NgSelectModule,  
    DragDropModule,
    CKEditorModule,
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    Ng2ImgMaxModule,
    NgxBarcodeModule,
    SidebarModule.forRoot(),
    DeviceDetectorModule.forRoot(),   
    NgxEmojiPickerModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-top-center'
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),   
     NgxChartsModule,    
     AgGridModule.withComponents([ImageformatterComponent,ButtonformatterComponent])
  ],
  schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe,NgxImageCompressService,DateFormatPipe,BnNgIdleService, DhukanDataService,LoginDataService],
  bootstrap: [AppComponent],
  exports: [BrowserModule, TranslateModule],
  entryComponents: [ ConfirmationDialogComponent,SalesfilterComponent
  
  ],
})
export class AppModule { }
