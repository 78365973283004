import { Component, OnInit } from '@angular/core';
import { DhukanDataService } from '../services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import { SalesfilterComponent } from '../components/salesfilter/salesfilter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
declare var google;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Dashboard";

  fromdate: any = new Date();
  todate: any = new Date();

  mainBO: any[] = [];
  branchColl: any[] = [];

  salesdetailscategoryallColl: any[] = [];
  salesdetailscategoryColl: any[] = [];

  
  salesdateallColl: any[] = [];
  salesdateColl: any[] = [];

  salesavgColl: any[] = [];

  saleswotaxColl: any[] = [];

  totalbills: number = 0;
  avgamt: number = 0;
  loyaltycus: number = 0;

  totalsales: number = 0;  
  card: number = 0;
  cash: number = 0;
  crnote: number = 0;
  credit: number = 0;
  fx: number = 0;
  other: number = 0;
  totalsaleswotax: number = 0;  
  
  categoryexpand: boolean = false
  dateexpand: boolean = false

  filterby: any = "TODAY"

  filterbranchColl: any = []

  //Chart COllection

  saleschtbranchColl: any[] = [];
  saleschtdateColl: any[] = [];

  //CHART
  view: any[] = [290, 350];
  view1: any[] = [290, 300];
  // options
  colorScheme = {
    domain: ['#1355b3', '#1355b3', '#1355b3', '#1355b3']
  };

  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;

  multi: any[];

  constructor(public appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    public confirmationDialogService: ConfirmationDialogService, public toastr: ToastrService, public productFindService: NgbModal,
    public loginService: LoginDataService, public datePipe: DatePipe) {

    this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnOnInitForm();
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  fnUserFailedForm() {
    this.isprivilage = true;
  }

  async fnOnInitForm() {
    this.isprivilage = false;
    this.isloading = true;

    await this.fngetUserBranch();
    this.fngetDasboard();

    this.fnLoadGeoLocation();


  }

  ngOnInit() {

    if (this.loginService.fnCheckUser()) {
     
      this.fnOnInitForm();
    }
    else {
      
      this.loginService.fnGetUserRoleList();
    }

  }


  fngetUserBranch() {

    var data = {
      userid: this.loginService.fnGetModUserId(),
      companyid: this.loginService.fnGetModCompanyId()
    }
    this.appService.getUserBranch(data).subscribe((res) => {

      if (res.json().status === 200) {

        this.filterbranchColl = res.json().result

      }
    },
      (err) => {
        this.toastr.error('Error', err);
      })
  }


  fngetDasboard() {

   
    if (this.showProgress) {
      return
    }
    
    this.showProgress = true;

    var data = {
      'startdate': this.fnFormatDatetime(this.fromdate),
      'enddate': this.fnFormatDatetime(this.todate),
      'userid': this.loginService.fnGetModUserId(),
      'filterby': this.filterby,
      'branchby': this.fnGetFilterBranch(),
    }
    this.appService.getDashboardSales(data)
      .subscribe(
        (res) => {
          this.showProgress = false;
          
          if (res.json().status === 200) {
            var result = res.json().result;
            
            //console.log(result)

            // Sumary  
            var mainBO = result[0][0]

       

            this.totalsales = mainBO.totalsales;
            this.totalbills = mainBO.totbills;
            this.avgamt = mainBO.avgamt;
            this.card = mainBO.paycard;
            this.cash = mainBO.paycash;
            this.crnote = mainBO.paycnote;
            this.credit = mainBO.paycredit;
            this.fx = mainBO.payfx;
            this.other = mainBO.payother;
            this.totalsaleswotax= mainBO.totalsaleswotax;

            this.fnSetDateFormat(mainBO)

            this.branchColl = result[1];

            //Category
            this.salesdetailscategoryallColl = result[2];

            this.salesdetailscategoryColl = [];
            for (var abo of this.salesdetailscategoryallColl) {
              if (!this.categoryexpand && this.salesdetailscategoryColl.length > 5) {
                continue;
              } else {
                this.salesdetailscategoryColl.push(abo);
              }
            }

            //Date
            this.salesdateallColl = result[5];

            this.salesdateColl = [];
            for (var abo of this.salesdateallColl) {
              if (!this.dateexpand && this.salesdateColl.length > 5) {
                continue;
              } else {
                this.salesdateColl.push(abo);
              }
            }

            this.fnSetChart();


            //Avg Bills            
            this.salesavgColl = result[6];

            this.saleswotaxColl = result[7];
            
            
          }
        },
        (err) => {
          this.showProgress = false;
          this.toastr.error('Error', err);
        })
  }
  
  fnSetChart() {
      this.saleschtbranchColl = [];
    this.saleschtbranchColl.push({ "name": "Daily", "series": this.branchColl })

    this.saleschtdateColl = [];
    this.saleschtdateColl.push({ "name": "Daily", "series": this.salesdateColl })
    
    
  }

  fnSetDateFormat(mainBO) {
    this.fromdate = this.fnConvertDatetime(this.fnFormatDatetime(mainBO.startdate));
    this.todate = this.fnConvertDatetime(this.fnFormatDatetime(mainBO.enddate));
  }

  fnCategoryExpandClick() {
    this.categoryexpand = !this.categoryexpand;

    this.salesdetailscategoryColl = [];

    for (var abo of this.salesdetailscategoryallColl) {
      if (!this.categoryexpand && this.salesdetailscategoryColl.length > 5) {
        continue;
      } else {
        this.salesdetailscategoryColl.push(abo);
      }
    }
  }

  
  fnDateExpandClick() {
    this.dateexpand = !this.dateexpand;

    this.salesdateColl = [];

    for (var abo of this.salesdateallColl) {
      if (!this.dateexpand && this.salesdateColl.length > 5) {
        continue;
      } else {
        this.salesdateColl.push(abo);
      }
    }

    this.fnSetChart()
  }

  fnAddSalesFilter() {
    const modalRef = this.productFindService.open(SalesfilterComponent, { size: <any>'l' ,backdrop: 'static'});
    modalRef.componentInstance.daterange = this.filterby;
    modalRef.componentInstance.fromdate = this.fromdate;
    modalRef.componentInstance.todate = this.todate;
    modalRef.componentInstance.userbranchColl = this.filterbranchColl;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      if (receivedEntry) {
        this.filterby = receivedEntry.daterange;
        this.filterbranchColl = receivedEntry.userbranchColl

        this.fromdate = receivedEntry.fromdate;
        this.todate = receivedEntry.todate;
      }
      this.fngetDasboard();

    });
  }

  fnGetFilterBranch() {
    var _filterstr = "";

    for (var abo of this.filterbranchColl) {
      if (abo.ischeck == 1) {
        if (_filterstr == '') {
          _filterstr = abo.branchid.toString();
        } else {
          _filterstr = _filterstr + "," + abo.branchid.toString();
        }
      }
    }

    return _filterstr;
  }





  fngetDasboard1() {

    var data = {

    }
    // this.appService.fnGetDashboardAdmin(data)
    // .subscribe(
    //   (res)=>{              
    //     if(res.json().status==200){                          
    //      var _res=res.json().result;

    //      this.summaryColl=_res[0];

    //      if(this.summaryColl.length>0){
    //       this.neworders=this.summaryColl[0].neworders;
    //       this.newcustomer=this.summaryColl[0].newcustomer;
    //       this.totalorders=this.summaryColl[0].totalorders;
    //       this.totalcustomer=this.summaryColl[0].totalcustomer;

    //       this.todaysales=this.summaryColl[0].todaysales;
    //       this.totalproducts=this.summaryColl[0].totalproducts;
    //       this.totalnodevice=this.summaryColl[0].totalnodevice;
    //        this.todayvisitors = this.summaryColl[0].todayvisitors;

    //        this.recentviewers= this.summaryColl[0].recentviewers;
    //      }

    //      this.recentcustomerColl=_res[1];
    //      this.mostviewproductsColl=_res[2];

    //       this.ordersummaryColl=_res[3];
    //      if(this.ordersummaryColl.length>0){
    //       this.placedcount=this.ordersummaryColl[0].placedcount;
    //       this.topackcount=this.ordersummaryColl[0].topackcount;
    //       this.readycount=this.ordersummaryColl[0].readytopickcount;
    //       this.assigncount=this.ordersummaryColl[0].assigncount;
    //       this.pickedcount=this.ordersummaryColl[0].pickedcount;
    //       this.deliveredcount=this.ordersummaryColl[0].deliveredcount;

    //      }

    //      this.branchwiseColl=_res[4];



    //      this.topsellingproductColl= _res[6];
    //      this.monthlywiseColl = _res[7];

    //      this.billwiseColl = _res[9];
    //      this.dailybillsales = [];
    //      this.dailybillsales.push({"name": "Daily","series": this.billwiseColl})

    //     }
    //     else if(res.json().status==400){                          
    //       this.toastr.error('Error', res.json().err_field);
    //     }                          
    // },
    //   (err)=>{                        
    //     this.toastr.error('Error', err);
    //  })
  }

  fnEditCustomer(deptBO) {
    try {


    } catch (ex) {
      // this.fnShowErrorMsg(ex);
    }
  }
  fnLoadGeoLocation() {


  }

  fnShowOrder(type) {
    try {


    } catch (ex) {
      // this.fnShowErrorMsg(ex);
    }
  }
  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      //  return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }

  }
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }
}
